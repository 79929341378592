<template>
  <div class="how-get-phone">
    <svg
      class="how-get-phone-frame"
      viewBox="0 0 513 947"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g filter="url(#filter0_d_30_23)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M469 321.785V804.352C469 832.916 466.03 843.275 460.452 853.717C454.874 864.16 446.689 872.356 436.259 877.941C425.829 883.526 415.484 886.5 386.956 886.5H126.044C97.5157 886.5 87.1706 883.526 76.7409 877.941C66.3113 872.356 58.1261 864.16 52.5482 853.717C46.9704 843.275 44 832.916 44 804.352V342.812C42.3431 342.812 41 341.467 41 339.808V281.734C41 280.075 42.3431 278.731 44 278.731V263.712C42.3431 263.712 41 262.367 41 260.708V202.634C41 200.975 42.3431 199.63 44 199.63V172.596C42.3431 172.596 41 171.251 41 169.592V141.557C41 139.898 42.3431 138.553 44 138.553V103.553C44 74.9883 46.9704 64.6301 52.5482 54.1872C58.1261 43.7444 66.3113 35.5488 76.7409 29.9639C87.1706 24.379 97.5157 21.4048 126.044 21.4048H386.956C415.484 21.4048 425.829 24.379 436.259 29.9639C446.689 35.5488 454.874 43.7444 460.452 54.1872C466.03 64.6301 469 74.9883 469 103.553V220.657C470.657 220.657 472 222.002 472 223.661V318.781C472 320.44 470.657 321.785 469 321.785ZM68 109.316C68 88.0071 68 77.3526 72.1418 69.2137C75.7849 62.0544 81.5982 56.2338 88.7484 52.586C96.877 48.439 107.518 48.439 128.8 48.439L144 48.439C147.684 48.4488 150.732 51.3076 150.998 54.9309C151.14 59.9103 152.1 63.5648 153.785 66.719C155.615 70.1456 158.301 72.8348 161.723 74.6673C165.145 76.4999 169.157 77.4758 174.814 77.4758H336.146C341.803 77.4758 345.815 76.4999 349.237 74.6673C352.659 72.8348 355.345 70.1456 357.175 66.719C358.857 63.5699 359.817 59.9222 359.961 54.9552C360.216 51.3204 363.269 48.4488 366.961 48.439L382.2 48.439C403.482 48.439 414.123 48.439 422.252 52.586C429.402 56.2338 435.215 62.0544 438.858 69.2137C443 77.3526 443 88.0071 443 109.316V800.591C443 821.9 443 832.555 438.858 840.694C435.215 847.853 429.402 853.673 422.252 857.321C414.123 861.468 403.482 861.468 382.2 861.468H128.8C107.518 861.468 96.877 861.468 88.7484 857.321C81.5982 853.673 75.7849 847.853 72.1418 840.694C68 832.555 68 821.9 68 800.591V109.316Z"
          fill="white"
          stroke="#ACACAC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_30_23"
          filterUnits="userSpaceOnUse"
        >
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="20" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_30_23"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <div class="how-get-phone-wrapper">
      <div class="how-get-phone-content mobile-view">
        <slot />
      </div>
    </div>
    <div class="how-get-phone-frame-top">
      <svg
        width="433"
        height="867"
        viewBox="0 0 433 867"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M429 301.785V784.352C429 812.916 426.03 823.275 420.452 833.717C414.874 844.16 406.689 852.356 396.259 857.941C385.829 863.526 375.484 866.5 346.956 866.5H86.0443C57.5157 866.5 47.1706 863.526 36.7409 857.941C26.3113 852.356 18.1261 844.16 12.5482 833.717C6.97042 823.275 4 812.916 4 784.352V322.812C2.34315 322.812 1 321.467 1 319.808V261.734C1 260.075 2.34315 258.731 4 258.731V243.712C2.34315 243.712 1 242.367 1 240.708V182.634C1 180.975 2.34315 179.63 4 179.63V152.596C2.34315 152.596 1 151.251 1 149.592V121.557C1 119.898 2.34315 118.553 4 118.553V83.5531C4 54.9883 6.97042 44.6301 12.5482 34.1872C18.1261 23.7444 26.3113 15.5488 36.7409 9.96387C47.1706 4.37897 57.5157 1.40479 86.0443 1.40479H346.956C375.484 1.40479 385.829 4.37897 396.259 9.96387C406.689 15.5488 414.874 23.7444 420.452 34.1872C426.03 44.6301 429 54.9883 429 83.5531V200.657C430.657 200.657 432 202.002 432 203.661V298.781C432 300.44 430.657 301.785 429 301.785ZM28 89.3161C28 68.0071 28 57.3526 32.1418 49.2137C35.7849 42.0544 41.5982 36.2338 48.7484 32.586C56.877 28.439 67.518 28.439 88.8 28.439L104 28.439C107.684 28.4488 110.732 31.3076 110.998 34.9309C111.14 39.9103 112.1 43.5648 113.785 46.719C115.615 50.1456 118.301 52.8348 121.723 54.6673C125.145 56.4999 129.157 57.4758 134.814 57.4758H296.146C301.803 57.4758 305.815 56.4999 309.237 54.6673C312.659 52.8348 315.345 50.1456 317.175 46.719C318.857 43.5699 319.817 39.9222 319.961 34.9552C320.216 31.3204 323.269 28.4488 326.961 28.439L342.2 28.439C363.482 28.439 374.123 28.439 382.252 32.586C389.402 36.2338 395.215 42.0544 398.858 49.2137C403 57.3526 403 68.0071 403 89.3161V780.591C403 801.9 403 812.555 398.858 820.694C395.215 827.853 389.402 833.673 382.252 837.321C374.123 841.468 363.482 841.468 342.2 841.468H88.8C67.518 841.468 56.877 841.468 48.7484 837.321C41.5982 833.673 35.7849 827.853 32.1418 820.694C28 812.555 28 801.9 28 780.591V89.3161Z"
          fill="white"
          stroke="none"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M262 43.4581C265.866 43.4581 269 40.3201 269 36.449C269 32.578 265.866 29.4399 262 29.4399C258.134 29.4399 255 32.578 255 36.449C255 40.3201 258.134 43.4581 262 43.4581Z"
          fill="#232323"
        />
        <rect
          x="190"
          y="32.4442"
          width="52"
          height="8.00977"
          rx="4"
          fill="#232323"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="css">
.how-get-phone {
  display: inline-block;
  position: relative;
}

.how-get-phone-frame,
.how-get-phone-wrapper {
  width: 513px;
  height: 947px;
}

.how-get-phone-wrapper {
  position: absolute;
  top: 0;
}

.how-get-phone-content {
  margin: 48px 68px;
}

.mobile-view {
  width: 375px;
  height: 813.03px;
  overflow: hidden;
  background: #ffffffff;
  border-radius: 37px;
}

.mobile-view h2 {
  font-size: 24px;
  line-height: 115%;
  letter-spacing: 0.03em;
}

.how-get-phone-frame-top {
  width: 433px;
  height: 58px;
  overflow: hidden;
  position: absolute;
  top: 20px;
  left: 40px;
}
</style>

<script>
export default {
  name: "HowGetPhone",
};
</script>
